import React from "react";
import './GameListPage.css';
import axios from 'axios';
import GameItem from "./GameItem";

class GameListPage extends React.Component {
    constructor(props) {
        super(props)
        this.requestGamesArray();
        this.state = {gamesArray:[]};
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render () {
        // const gameArray = [{
        //     "ItemId":1,
        //     "ItemName":"小球弹弹弹",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/small-ball/index.html"
        // },{
        //     "ItemId":2,
        //     "ItemName":"magic2048",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/magic-2048/index.html"
        // },{
        //     "ItemId":3,
        //     "ItemName":"飞行棋",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/fly-chess/index.html"
        // },{
        //     "ItemId":4,
        //     "ItemName":"合成大西瓜",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/syn-watermelon/index.html"
        // },{
        //     "ItemId":5,
        //     "ItemName":"羊了个羊",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/sheep-x3/index.html"
        // },{
        //     "ItemId":6,
        //     "ItemName":"倒水",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/water-puzzle/index.html"
        // },{
        //     "ItemId":7,
        //     "ItemName":"汉诺塔",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/hanoi/index.html"
        // },{
        //     "ItemId":8,
        //     "ItemName":"弓箭",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/arrow/index.html"
        // },{
        //     "ItemId":9,
        //     "ItemName":"方向",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/direction/index.html"
        // },{
        //     "ItemId":10,
        //     "ItemName":"card2048",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/card-2048/index.html"
        // },{
        //     "ItemId":11,
        //     "ItemName":"水果三消",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/fruit-x3/index.html"
        // },{
        //     "ItemId":12,
        //     "ItemName":"进击的小趴菜",
        //     "URL":"https://www.5igamedev.com/gameweb/minigame/little-pucai/index.html"
        // }]

        return (
            <div className="area-layout">
                <div className="game-platform-name">微信小游戏</div>
                <div className="game-grid-container">
                    {this.state.gamesArray.map((element, index) => (
                        <GameItem element={element}/>
                    ))}
                </div>
                {/* <div className="game-platform-name">网页小游戏</div>
                <div className="game-grid-container">
                    {gameArray.map((element, index) => (
                        <div className="game-grid-item" key={element["ItemId"]} url={element["URL"]} onClick={this.onClickGameItem.bind(this, element)}>{element["ItemName"]}</div>
                    ))}
                </div> */}
            </div>
            
        );
        
    }

    requestGamesArray() {
        let minigames = [];

        axios.get('https://www.5igamedev.com/gameweb/wechat_games/wechat_games.json')
            .then(response => {
                console.log(response);
                
                let request_mini_game = JSON.parse(response);
                request_mini_game.forEach(element => {
                    if (element['type'] === "MINI_GAME") {
                        minigames.push(element);
                    }
                });
                this.setState({gamesArray:minigames});
            }).catch(error => {
                this.setState({gamesArray:[
                    {"name":"蔬菜派对","appId":"wxf6c235d3894d8485","icon":"https://www.5igamedev.com:443/gameweb/wechat_games/sheep-3.jpg","type":"MINI_GAME"},
                    {"name":"乐游舫坦克游戏","appId":"wx69753660b2759040","icon":"https://www.5igamedev.com:443/gameweb/wechat_games/tank.png","type":"MINI_GAME"},
                    {"name":"圣诞消消消","appId":"wx4abe2d67314af42a","icon":"https://www.5igamedev.com:443/gameweb/wechat_games/shot-duck.jpg","type":"MINI_GAME"},
                    {"name":"乐游舫变妆达人","appId":"wx823cc9c749390c6f","icon":"https://www.5igamedev.com:443/gameweb/wechat_games/girl.jpg","type":"MINI_GAME"},
                    {"name":"乐游舫森林迷宫","appId":"wxea529874cec9d752","icon":"https://www.5igamedev.com:443/gameweb/wechat_games/redriding.jpg","type":"MINI_GAME"},
                    {"name":"乐游舫消消乐","appId":"wx58022c8bdc15c714","icon":"https://www.5igamedev.com:443/gameweb/wechat_games/sweetcandy.png","type":"MINI_GAME"},
                    {"name":"乐游舫坦克","appId":"wxb23c27d1fcfc1086","icon":"https://www.5igamedev.com:443/gameweb/wechat_games/tankworld.jpg","type":"MINI_GAME"},
                    {"name":"乐游舫水果排序","appId":"wx89ed6642f2ee778c","icon":"https://www.5igamedev.com:443/gameweb/wechat_games/fruit.jpg","type":"MINI_GAME"},
                    {"name":"对对碰卡片王","appId":"wxcdd68fc569495193","icon":"https://www.5igamedev.com:443/gameweb/wechat_games/doushouqi.jpg","type":"MINI_GAME"},
                ]});
            });
    }

    onClickGameItem(gameElement, event) {
        window.open(gameElement["URL"]);
    }
}

export default GameListPage;