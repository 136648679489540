import React from "react";
import './MainPage.css'
import HomePage from "../HomePage/HomePage";
import GameListPage from "../GameListPage/GameListPage";
import AboutUs from "../AboutUsPage/AboutUs";
// import { BrowserRouter as Route, Router, Switch } from "react-router-dom";

class MainPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIndex:0
        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render () {
        
        return (
            // <Router>
            //     <Switch>
            //         <Route path="/" exact component={HomePage} />
            //         <Route path="/gamelist" component={GameListPage}/>
            //         <Route path="/aboutus" component={AboutUs}/>
            //     </Switch>
            // </Router>
            <div>
                {this.getCurrentContentPage()}
            </div>
        );
    }

    getCurrentContentPage = () => {
        const {tabIndex} = this.props;
        switch (tabIndex) {
            case 0:
                return <HomePage/>;
            case 1:
                return <GameListPage/>;
            case 2:
                return <AboutUs/>;
            default:
                return <HomePage/>;
        }
    }
}

export default MainPage;