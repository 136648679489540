import React from "react";
import './UIBottom.css'
import wigamefunlogo from '../../images/home/wigamefun-qrcode.jpg';
class UIBottom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {date:new Date()};
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    render () {
        return (
            <div className="bottom-background">
                <div className="bottom-stytle">
                <img className="App-logo-bottom" alt="logo-white" src={wigamefunlogo}/>
                    <div className="bottom-title">
                        黑蚂蚁游戏工作室
                        <div className="bottom-sub-title">
                            BLACK ANT CLUB
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default UIBottom;