import React from "react";
import './AboutUs.css'

class AboutUs extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render () {
        return (
            <div>
                关于我们
            </div>
        );
    }
}

export default AboutUs;