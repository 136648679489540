import React from "react";
import './HomePage.css'
import minigamelogo from '../../images/home/minigame.png';

class HomePage extends React.Component {
    constructor(props) {
        super(props)
        this.setState({itemArray:[{
            "ItemId":1,
            "ItemName":"微信小游戏自研"
        }]})
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render () {
        const gameArray = [{
            "ItemId":1,
            "ItemName":"微信小游戏开发"
        }]

        return (
            <div className="grid-single-container">
                {gameArray.map((element, index)=>(
                    <div className="grid-single-item">
                        <div className="mini-game-icon"><img alt="minigame" src={minigamelogo}></img></div>
                        <div className="grid-sinle-name">{element["ItemName"]}</div>
                    </div>
                ))}
            </div>
        );
    }
}

export default HomePage;