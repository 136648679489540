
import './App.css';
import WebPage from './UIComponent/Web/WebPage';


function App() {
  return (
    <div className="App">
      <WebPage/>
    </div>
  );
}

export default App;
