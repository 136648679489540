import React from "react";
import './UITitle.css'

class UITitle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {date:new Date()};
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    render () {
        return (
            <div className="title-style">
                <div className="title">
                    黑蚂蚁游戏工作室
                    <div className="sub-title">
                        BLACK ANT CLUB
                    </div>
                </div>
            </div>
        );
    }
}

export default UITitle;