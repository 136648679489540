import React from "react";
import './Tables.css'

import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class Tables extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            tabIndex:0,
            setTabIndex:0,
        };
        this.onChangeTableIndex = this.onChangeTableIndex.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render () {
        const {
            onChangeTableIndex, 
            state:{
                tabIndex
            }
        } = this;

        return (
            <div className="tables">
               <Tabs selectedIndex={tabIndex} onSelect={onChangeTableIndex}>
                    <TabList className="">
                        <div>
                            <Tab><div className="tab-name">官网首页</div></Tab>
                            <Tab><div className="tab-name">游戏展示</div></Tab>
                            {/* <Tab><div className="tab-name">关于我们</div></Tab> */}
                            {/* <Link to="/">官网首页</Link>
                            <Link to="/gamelist">游戏展示</Link>
                            <Link to="/aboutus">关于我们</Link> */}
                        </div>
                    </TabList>
                </Tabs> 
            </div>
        );
    }

    onChangeTableIndex = (index) => {
        this.setState({tabIndex:index});
        this.props.onChangeTableIndex(index);
    }
}

export default Tables;