import React from "react";
import './GameItem.css';

class GameItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isVisible:false,
            element:{"name":"","appId":"","icon":"","type":""},
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render () {
        const {isVisible, element} = this.props;
        return (
            <div className="game-grid-item" key={element["appId"]} url={element["icon"]} onClick={this.onClickMiniGame.bind(this, element)}>
                <div><img className="game-icon" alt={element["icon"]} src={element["icon"]}/></div>
                <div className="game-name">{element["name"]}</div>
                {isVisible && <div className="game-operation">
                    <div className="game-operation-title">微信版</div>
                    <div className="game-operation-title">抖音版</div>
                    <div className="game-operation-title">网页版</div>
                </div>}
            </div>
        );
        
    }

    onClickMiniGame(gameElement, event) {
        // window.open(gameElement["icon"]);
    }
}

export default GameItem;