import React from "react";
import './WebPage.css'

import UITitle from './UITitle';
import Tables from './Tables';
import MainPage from '../MainPage/MainPage';

import wigamefunlogo from '../../images/home/logo-white.png';
import UIBottom from "./UIBottom";

class WebPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIndex:0
        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render () {
        return (
            <div>
                <header>
                    
                </header>
                <body className="App-body">
                    <div className="App-title">
                        <img className="App-logo-style" alt="logo-white" src={wigamefunlogo}/>
                        <UITitle/>
                    </div>
                    <Tables tableIndex={this.state.tabIndex} onChangeTableIndex={this.onChangeTableIndex}/>
                    <MainPage tabIndex={this.state.tabIndex}/>
                    <UIBottom/>
                </body>
            </div>
        );
    }

    onChangeTableIndex = (tableIndex) => {
        this.setState({tabIndex:tableIndex});
    }
}

export default WebPage;